<template>
  <div>
    <h1 class="text-h4">{{ $lang.header.roleDetails }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-0">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row wrap no-gutters class="pb-4">
      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <v-card class="pa-2">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row wrap no-gutters justify="space-between" class="pb-1">
              <v-col
                cols="12"
                class="pr-sm-1"
              >
                <v-text-field
                  v-model="role.name"
                  outlined
                  dense
                  :label="$lang.labels.name"
                  :hint="formatHint"
                  :persistent-hint="isEdit"
                  required
                  class="required-asterisk"
                  :rules="[v => !!v || 'Required!']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters wrap justify="space-between">
              <v-col
                cols="6"
                class="text-left"
              >
                <div class="d-inline-flex align-center">
                  <v-btn
                    class="button-default-width"
                    color="secondary"
                    :to="{ name: 'roles', params: { lang: $lang.current_lang } }"
                  >
                    <v-icon
                      right
                      dark
                      class="ma-0"
                    >
                      mdi-arrow-left
                    </v-icon>
                    <span class="ml-1">{{ $lang.actions.back }}</span>
                  </v-btn>
                  <action-button-with-confirmation
                    v-if="isEdit"
                    :action-text="$lang.actions.areYouSureYouWantToDelete"
                    :action-text-suffix="role.name"
                    :title="$lang.actions.delete"
                    type="role"
                    :is-disabled="!userCanDelete"
                    :button-text="$lang.actions.delete"
                    :button-color="'error'"
                    :data-cy="'role-delete'"
                    :forced-option="true"
                    :trigger-force-logic="triggerForceLogic"
                    class="ml-2"
                    :regular-delete-errors-usages="regularDeleteErrorsUsages"
                    :regular-delete-errors-resources="regularDeleteErrorsResources"
                    :regular-delete-errors-users="regularDeleteErrorsUsers"
                    :delete-success="deleteSuccess"
                    @submit="deleteRoleFunct($event)"
                    @closeAfterDelete="$router.push({ name: 'roles' })"
                    @closeDialog="''"
                  />
                </div>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <v-btn
                  :disabled="!valid || lock"
                  color="success"
                  class="button-default-width"
                  @click="submit()"
                >
                  {{ $lang.actions.submit }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  createRoleUsingPOST as createRole, deleteRoleUsingDELETE as deleteRole, getProcessByIdUsingGET as getProcess,
  getRoleByIdUsingGET as getRole, updateRoleUsingPUT as updateRole
} from '@/utils/api'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

export default {
  components: { ActionButtonWithConfirmation },
  data() {
    return {
      err: '',
      success: '',
      valid: false,
      loading: false,
      lock: false,
      role: {
        id: '',
        name: '',
        isAssignableToUsers: false,
        isSystem: false,
        createdOn: '',
        modifiedOn: ''
      },
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isEdit: false,
      regularDeleteErrorsResources: [],
      regularDeleteErrorsUsers: []
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.role.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.role.modifiedOn)}, ID: ${this.role.id}`
    }
  },
  created() {
    this.isEdit = this.$route.name === 'roleEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'ROLE_DELETER') || this.isSuperUser
    }
    if (this.isEdit && this.$route.params.id) {
      this.loading = true
      getRole({ id: this.$route.params.id })
        .then((res) => {
          this.role = res.data.data

          this.loading = false
        })
        .catch((err) => {
          this.err = err
          this.loading = false
        })
    } else {
      if (this.$route.params.copy) {
        this.role = this.$route.params.copy
        this.loading = false
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    deleteRoleFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteRole({ id: this.role.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
            this.regularDeleteErrorsResources = res.response.data.data.relatedResources
            this.regularDeleteErrorsUsers = res.response.data.data.relatedUsers
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    async submit() {
      this.lock = true

      let res = null

      try {

        res = this.isEdit ? await updateRole({ id: this.role.id, request: { name: this.role.name } }) : await createRole({ request: this.role })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.roleUpdate : this.$lang.errors.roleCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.$lang.success.roleCreated

        this.$router.push({
          name: 'roles',
          params: {
            message: this.success
          }
        })

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
